import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../../components/seo";
import { PAGE } from "../../helpers/constants";
import useTranslations from "../../hoc/useTranslations";
import { Link } from "gatsby";
import { routes } from "../../routes";
import { LocaleContext } from "../../components/Layout/Layout";
import "./ThankYou.scss";

export const ThankYou = () => {
	const {
		thankYou: { thank_you_title, for_signing, sub_title, our_blog },
	} = useTranslations();

	const { locale } = useContext(LocaleContext);

	const data = useStaticQuery(graphql`
		query {
			thank_you: file(relativePath: { eq: "thankYou.png" }) {
				childImageSharp {
					fluid(maxWidth: 600, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`);

	const { fluid: thank_you } = data.thank_you.childImageSharp;

	return (
		<>
			<SEO page={PAGE.THANK_YOU} />
			<div className="thank_you_wrapper">
				<div className="thank_you_header_container">
					<h1>
						{thank_you_title}
						<span>{for_signing}</span>
					</h1>
					<div className="thank_you_image_container_mobile">
						<Img fluid={thank_you} title={thank_you_title} alt={thank_you_title} />
					</div>
					<h2>{sub_title}</h2>
					<Link to={routes[locale].blog} title={our_blog}>
						{our_blog}
					</Link>
				</div>
				<div className="thank_you_image_container_desktop">
					<Img fluid={thank_you} title={thank_you_title} alt={thank_you_title} />
				</div>
			</div>
		</>
	);
};
