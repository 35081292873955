import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { PAGE } from "../helpers/constants";
import { LocaleContext } from "./Layout/Layout";
import useTranslations from "../hoc/useTranslations";
import { useLocation } from "@reach/router";
import { getPathName } from "../helpers/helpers";

function SEO({ meta, page }) {
	const { pathname } = useLocation();

	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
				}
			}
			logo: file(relativePath: { eq: "hero-banner.png" }) {
				childImageSharp {
					fluid(quality: 100) {
						src
					}
				}
			}
			ai_banner: file(relativePath: { eq: "ai_hero.png" }) {
				childImageSharp {
					fluid(quality: 100) {
						src
					}
				}
			}
		}
	`);

	const { src: imageUrl } = data.logo.childImageSharp.fluid;
	const { src: aiImageUrl } = data.ai_banner.childImageSharp.fluid;
	const { title: metaDefaultTitle } = data.site.siteMetadata;

	const defaultTitle =
		page === PAGE.INDEX ? `${metaDefaultTitle} - %s` : `%s | ${metaDefaultTitle}`;

	const { locale } = useContext(LocaleContext);
	const {
		meta_data: {
			[page]: { title, description: metaDescription },
		},
	} = useTranslations();

	return (
		<Helmet
			htmlAttributes={{
				locale,
				lang: locale,
			}}
			title={title}
			titleTemplate={defaultTitle}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:site_name`,
					content: metaDefaultTitle,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: "og:image",
					content: page === PAGE.CHAT_GPT ? aiImageUrl : imageUrl,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}
		>
			<link
				rel="cannonical"
				href={
					process.env.GATSBY_HOSTNAME + getPathName((locale === "en" ? "/pl" : "") + pathname, "en")
				}
			/>
		</Helmet>
	);
}

SEO.defaultProps = {
	page: PAGE.INDEX,
	meta: [],
};

SEO.propTypes = {
	page: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
